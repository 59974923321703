<template>
  <q-intersection transition="slide-down">
    <q-card flat bordered class="q-pa-md q-mt-md" v-if="campaignValidationHistory && Object.keys(campaignValidationHistory).length > 0" style="background-color: #f3e5f5">
      <div class="row q-pt-md q-gutter-x-sm">
        <div class="col-4">
          <p>
            <b>ID Campanha:</b> <span>{{ campaignValidationHistory.campaign_id }}</span>
          </p>
          <p>
            <b>Comissão Full:</b>
            <span>
              {{
                campaignValidationHistory.comissao_full
                  ? parseFloat(campaignValidationHistory.comissao_full).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : null
              }}</span
            >
          </p>
          <p>
            <b>Comissão Full Aprovada:</b>
            <span>
              {{
                campaignValidationHistory.comissao_full_aprovada
                  ? parseFloat(campaignValidationHistory.comissao_full_aprovada).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : null
              }}</span
            >
          </p>
          <p>
            <b>Margem:</b>
            <span>
              {{
                campaignValidationHistory.margem
                  ? parseFloat(campaignValidationHistory.margem).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : null
              }}</span
            >
          </p>
          <p>
            <b>Margem Aprovada:</b>
            <span>
              {{
                campaignValidationHistory.margem_aprovada
                  ? parseFloat(campaignValidationHistory.margem_aprovada).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : null
              }}</span
            >
          </p>
          <p>
            <b>Custo Afiliado:</b>
            <span>
              {{
                campaignValidationHistory.custo_afiliado
                  ? parseFloat(campaignValidationHistory.custo_afiliado).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : null
              }}</span
            >
          </p>
        </div>
        <div class="col-5">
          <p>
            <b>Custo Afiliado Aprovado:</b>
            <span>
              {{
                campaignValidationHistory.custo_afiliado_aprovado
                  ? parseFloat(campaignValidationHistory.custo_afiliado_aprovado).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : null
              }}</span
            >
          </p>
          <p>
            <b>Status:</b> <span>{{ campaignValidationHistory.status }}</span>
          </p>
          <p>
            <b
              >Taxa de captação:<q-icon name="mdi-help-circle-outline" size="1.1em"
                ><q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Conversão total aprovada / Conversão total captada <br />(Em caso de validação concluída)
                </q-tooltip></q-icon
              ></b
            >
            <span>&nbsp;{{ campaignValidationHistory.taxa_captacao.toFixed(2) }} %</span>
          </p>
          <p>
            <b
              >Taxa de validação:<q-icon name="mdi-help-circle-outline" size="1.1em"
                ><q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Comissão full aprovada / Comissão full captada <br />(Em caso de validação concluída)
                </q-tooltip></q-icon
              ></b
            >
            <span>&nbsp;{{ campaignValidationHistory.taxa_validacao.toFixed(2) }} %</span>
          </p>
          <p>
            <b
              >Comparativo de Comissão Full:<q-icon name="mdi-help-circle-outline" size="1.1em"
                ><q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                  Comparativo com mês anterior de comissão full validada<br />(Comissão full mês atual / Comissão full
                  mês anterior)
                </q-tooltip></q-icon
              ></b
            >
            <span>&nbsp;R$ {{ campaignValidationHistory.comissao_mes_anterior }}</span>
          </p>
          <p>
            <b>Comparativo de margem validada:</b> <span>R$ {{ campaignValidationHistory.margem_mes_anterior }}</span>
          </p>
        </div>
        <div class="col-2">
          <p>
            <b
              >Total:</b
            >
            <span> {{ campaignValidationHistory.total }}</span>
          </p>
          <p>
            <b>Pendentes:</b> <span>{{ campaignValidationHistory.pendentes }}</span>
          </p>
          <p>
            <b>Aprovadas:</b> <span>{{ campaignValidationHistory.aprovadas }}</span>
          </p>
          <p>
            <b>Recusadas:</b> <span>{{ campaignValidationHistory.recusadas }}</span>
          </p>
          <p>
            <b>Margem extra:</b>
            <span>
              {{
                campaignValidationHistory.margem_extra
                    ? parseFloat(campaignValidationHistory.margem_extra).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })
                    : 'R$ 0'
              }}</span
            >
          </p>
        </div>
      </div>

      <div>
    <div class="q-mb-md q-mt-sm" style="text-align: right;">
        <q-btn @click="toggleExpand" :label="isExpanded ? 'Recolher Dados' : 'Exibir Dados de Envio'" color="primary" :icon-right="isExpanded ? 'expand_less' : 'expand_more'"/>
    </div>

    <div v-if="isExpanded" style="background-color: white; padding: 1rem; border-radius: 0.5rem; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
        <q-list v-if="emailsSent.length > 0">
            <q-item v-for="email in emailsSent" :key="email.id" class="q-mb-sm">
                <q-item-section>
                    <div><strong>Enviado por:</strong> {{ email.user_email }}</div>
                </q-item-section>
                <q-item-section>
                    <div><strong>Data de envio:</strong> {{ formatDate(email.email_sent_at) }}</div>
                </q-item-section>
                <q-item-section>
                    <div><strong>Valor da Margem extra:</strong> {{ formatCurrency(email.value) }}</div>
                </q-item-section>
            </q-item>
        </q-list>
        <div v-else class="flex flex-center">
            Nenhum dado disponível.
        </div>
    </div>
</div>
    </q-card>
    <div>
      <q-btn
        v-if="campaignValidationHistory && Object.keys(campaignValidationHistory).length > 0"
        icon="email"
        label="E-mail"
        color="primary"
        @click="openEmailField"
        class="q-mt-md"
        ref="emailButton"
        style="margin-right: 10px;"
      >
        <q-tooltip>Enviar informações por e-mail</q-tooltip>
      </q-btn>

      <q-btn
        v-if="campaignValidationHistory && Object.keys(campaignValidationHistory).length > 0 && !form.extra_margin"
        :label="campaignValidationHistory.margem_extra > 0 ? 'Editar margem extra' : 'Adicionar margem extra'"
        color="secondary"
        @click="openExtraMarginField"
        class="q-mt-md"
        clearable
      />

      <q-btn
        v-if="campaignValidationHistory && Object.keys(campaignValidationHistory).length > 0 && form.extra_margin"
        label="Editar margem extra"
        color="secondary"
        @click="openExtraMarginField"
        class="q-mt-md"
        clearable
      />
    </div>

    <div v-if="isEditingExtraMargin && Object.keys(campaignValidationHistory).length > 0" class="q-gutter-md" style="max-width: 600px; margin-top: 0.5rem;">
      <q-input
        outlined
        dense
        clearable
        label="Margem extra"
        v-model="form.extra_margin"
        type="number"
        min="0"
      />
      <div class="button-container">
        <q-btn label="Cancelar" color="primary" class="button-spacing" @click="cancelExtraMarginEdit" />
        <q-btn label="Salvar" color="primary" class="button-spacing" @click="saveExtraMargin" />
      </div>
    </div>

    <template>
      <div v-if="showAdditionalInfo && !showMarginExtra" class="q-gutter-md" style="max-width: 600px; margin-top: 0.5rem;">
        <q-input
          outlined
          autogrow
          dense
          clearable
          label="Informações adicionais"
          v-model="form.additional_informations"
        >
          <template v-slot:prepend>
            <q-icon
            name="mdi-image-plus"
            class="upload-icon"
            :color="form.file ? 'primary' : 'grey'"
            @click="openFileDialog"
            />
          </template>
          <input type="file" ref="fileInput" style="display: none;" @change="handleFileChange" />
        </q-input>
        <div  class="button-container">
          <q-btn label="Cancelar" color="primary" class="button-spacing" @click="resetEmailField" />
          <q-btn label="Enviar" color="primary" class="button-spacing" @click="sendEmailWithAttachment" />
        </div>
      </div>
    </template>

    <q-card flat bordered class="q-pa-md q-mt-md text-center" v-if="campaignValidationHistory && Object.keys(campaignValidationHistory).length == 0" style="background-color: #f3e5f5">
      Não há histórico disponível neste momento.
    </q-card>
  </q-intersection>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import ReportMixin from '../../mixins/ReportMixin';

export default {
  name: 'ListValidationHistory',
  mixins: [CampaignMixin, ReportMixin],

  data() {
    return {
      form: {
        additional_informations: '',
        file: null,
        extra_margin: ''
      },
      showAdditionalInfo: false,
      isEditingExtraMargin: false,
      showMarginExtra: false,
      emailsSent: [],
      isExpanded: false
    };
  },

  methods: {
    async toggleExpand() {
      if (this.isExpanded) {
        this.isExpanded = false;
      } else {
        await this.fetchEmailsSent();
        this.isExpanded = true;
      }
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.form.file = file;
    },
    openEmailField() {
      this.showAdditionalInfo = true;
      this.isEditingExtraMargin = false;
    },
    resetEmailField() {
      this.showAdditionalInfo = false;
    },
    openExtraMarginField() {
      this.isEditingExtraMargin = true;
      this.showAdditionalInfo = false;
    },
    cancelExtraMarginEdit() {
      this.isEditingExtraMargin = false;
      this.form.extra_margin = null;
    },

    async saveExtraMargin() {
      try {
        this.onLoading(true);

        const response = await this.createExtraMargin(
            this.campaignValidationHistory.campaign_id,
            this.form.extra_margin,
            this.user.email,
        );

        if (response && (response.status === 200 || response.status === 201)) {
            this.successNotify('Margem extra salva com sucesso!');
            this.$set(this.campaignValidationHistory, 'margem_extra', this.form.extra_margin);
            this.isEditingExtraMargin = false;
            this.form.extra_margin = null;
        }

      } catch (error) {
        console.error(error);
        this.errorNotify('Erro ao salvar a margem extra.');
      } finally {
        this.onLoading(false);
      }
    },

    async sendEmailWithAttachment() {
      try {
        this.onLoading(true);

        let currentDateTime = new Date().toISOString();

        let formData = new FormData();

        if (this.form.file) {
          formData.append('file', this.form.file);
        } else {
          const emptyFile = new File([''], 'empty.txt', { type: 'text/plain' });
          formData.append('file', emptyFile);
        }

        if (this.campaignValidationHistory.margem_extra > 0) {
            this.createExtraMargin(
                this.campaignValidationHistory.campaign_id,
                this.campaignValidationHistory.margem_extra,
                this.user.email,
                currentDateTime,
            );
        }

        formData.append('additional_informations', this.form.additional_informations);
        formData.append('data', JSON.stringify(this.campaignValidationHistory));

        const { status } = await this.$http.post('/v2/redirect?app=REPORT&path=/api/transaction/history/send', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            },
        });

        if (status === 200 || status === 201) {
          this.successNotify('Email enviado com sucesso!');
        }

      } catch (error) {
        console.log(error);
        this.errorNotify('Falha ao enviar!');
      } finally {
        this.onLoading(false);
      }
    },

    async fetchEmailsSent() {
      if (!this.campaignValidationHistory || !this.campaignValidationHistory.campaign_id) {
        this.errorNotify('Campanha não encontrada.');
        return;
      }

      try {
        const response = await this.getLatestEmailSent(this.campaignValidationHistory.campaign_id);
        this.emailsSent = response;

      } catch (error) {
        this.errorNotify('Erro ao buscar os e-mails enviados.');
      } finally {
        this.isLoading = false;
      }
    },

    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString('pt-BR', options);
    },

    formatCurrency(value) {
      return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
    }
 }
};
</script>

<style>
.upload-icon {
  font-size: 24px;
  color: #757575;
  cursor: pointer;
}
.upload-icon:hover {
  color: #2196F3;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.button-spacing {
  margin-left: 10px;
}
</style>

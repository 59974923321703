<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Histórico de Validação</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Histórico de Validação" exact />
      </q-breadcrumbs>
    </div>
    <q-page padding>
      <header-validation-history :campaignList="campaignList"></header-validation-history>
      <list-validation-history />
    </q-page>
  </q-layout>
</template>

<script>
import HeaderValidationHistory from '../../components/campaign/HeaderValidationHistory.vue';
import ListValidationHistory from '../../components/campaign/ListValidationHistory.vue';

export default {
  components: { HeaderValidationHistory, ListValidationHistory },
  name: 'ValidationHistory',

  created() {
    this.getCampaigns();
  },

  data() {
    return {
      campaignList: []
    };
  },

  methods: {
    async getCampaigns() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/get/campaign/all');
        if (status === 200) {
          this.campaignList = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    }
  }
};
</script>

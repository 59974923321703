<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Bônus </q-tooltip></q-btn
      >
    </q-toolbar>
    <q-intersection transition="slide-down" v-show="filter">
      <q-card flat v-if="filter" bordered class="q-mb-sm">
        <q-toolbar>
          <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <q-select
              v-model="params.campaign"
              :label="$t('campaign')"
              outlined
              use-input
              input-debounce="200"
              @filter="filterCampaignSelect"
              :options="campaignFiltered"
              option-label="name"
              option-value="id"
              clearable
            />
          </div>
          <div class="col-6">
            <q-input outlined readonly v-model="params.date" mask="##/####" fill-mask="##/####" @click="$refs.qDateProxy.show();">
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date
                      minimal
                      mask="MM/YYYY"
                      emit-immediately
                      v-model="params.date"
                      default-view="Years"
                      @input="controllerDates"
                      :options="[{ view: ['Years', 'Months'] }]"
                    >
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>

          </div>

          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter"/>
            <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterHistory" :disable="!params.campaign || !params.date"/>
          </div>
        </q-card-section>
      </q-card>
    </q-intersection>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';

export default {
  name: 'HeaderValidationHistory',
  mixins: [CampaignMixin],

  props: {
    campaignList: []
  },

  data() {
    return {
      filter: true,
      params: {
        campaign: null,
        date: null
      },
      campaignFiltered: [],
      countclickedDate: 0,
    };
  },

  methods: {
    controllerDates() {
      this.countclickedDate += 1;
      if (this.countclickedDate >= 2) {
        this.countclickedDate = 0;
        this.$refs.qDateProxy.hide();
      }
    },

    clearFilter() {
      for (let i in this.params) this.params[i] = null;
      this.ActionSetCampaignValidationHistory();
    },

    filterCampaignSelect(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignFiltered = this.campaignList;
        this.campaignFiltered = this.campaignList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    onFilterHistory() {
      let filterSelect = [];

      if (this.params.campaign) filterSelect.push(`campaign_id=${this.params.campaign.id}`);

      if (this.params.date) {
        let date = this.params.date.split('/');
        filterSelect.push(`periodo=${date[1]}-${date[0]}-01`);
      }

      this.getValidationHistory(filterSelect.join('%26'));
    }
  },

};
</script>
